<template>
  <div>
    <div v-if="isLoadingElearningList">Loading...</div>
    <div v-else>
      <ValidationObserver>
        <form @submit.prevent="saveItem">
          <b-card>
            <b-row>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <label for="name">Nama Bank:</label>
                  <validation-provider
                    name="name"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      id="name"
                      type="text"
                      class="form-control"
                      :class="classes"
                      :state="errors.length > 0 ? false : null"
                      v-model="formPayload.name"
                    />
                    <small
                      v-for="(validation, index) in validations.name"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="description">Deskripsi:</label>
                  <validation-provider
                    name="description"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <textarea
                      id="description"
                      class="form-control"
                      :class="classes"
                      :state="errors.length > 0 ? false : null"
                      v-model="formPayload.description"
                    ></textarea>
                    <small
                      v-for="(validation, index) in validations.description"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="type">Status:</label>
                  <div class="demo-inline-spacing" style="margin-top: 8px">
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="headlineOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio1"
                        value="publish"
                        v-model="formPayload.status"
                      />
                      <label class="custom-control-label" for="radio1">
                        Published
                      </label>
                    </div>
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="headlineOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio2"
                        value="unpublish"
                        v-model="formPayload.status"
                      />
                      <label class="custom-control-label" for="radio2">
                        Unpublish
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="banner">Banner:</label><br />
                  <p class="image__hint"><i>Only Jpg, Image</i></p>
                  <b-form-file
                    id="banner"
                    class="mb-1"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="image/jpeg, image/png"
                    @change="changeFile($event, 'banner')"
                  />
                  <small>Current Banner:</small><br />
                  <img
                    v-if="image_url !== null"
                    :src="image_url"
                    class="media_file"
                  />
                  <small class="text-danger">{{ validationsCategory }}</small>
                </div>
              </b-col>

              <b-col cols="12" md="6">
                <div class="form-group">
                  <label for="class">Pilih E-learning:</label>
                  <validation-provider
                    name="information[class]"
                    rules="required"
                  >
                    <v-select
                      label="name"
                      v-model="selectedOption"
                      :options="eLearningList"
                      placeholder="-- Pilih --"
                      :reduce="(option) => option"
                      @keyup.stop.native="searchCategories"
                    />
                    <!-- <small class="text-danger">{{ validationsCategory }}</small> -->
                    <small
                      >Jika tidak ada dalam daftar silahkan ketik keyword untuk
                      mencari:</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="title">Judul:</label>
                  <validation-provider
                    name="title"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      id="title"
                      type="text"
                      class="form-control"
                      :class="classes"
                      :state="errors.length > 0 ? false : null"
                      v-model="formPayload.information.title"
                    />
                    <small
                      v-for="(validation, index) in validations.title"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="info">Info:</label>
                  <validation-provider
                    name="info"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <textarea
                      id="info"
                      class="form-control"
                      :class="classes"
                      :state="errors.length > 0 ? false : null"
                      v-model="formPayload.information.product_info"
                    ></textarea>
                    <small
                      v-for="(validation, index) in validations.product_info"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="loan">Url Bank:</label>
                  <validation-provider
                    name="loan"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      id="loan"
                      type="text"
                      class="form-control"
                      :class="classes"
                      :state="errors.length > 0 ? false : null"
                      v-model="formPayload.information.loan_link"
                    />
                    <small
                      v-for="(validation, index) in validations.loan_link"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider>
                </div>
                <div class="form-group">
                  <label for="logo">Logo:</label><br />
                  <p class="image__hint"><i>Only Jpg, Image</i></p>
                  <b-form-file
                    id="logo"
                    class="mb-1"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="image/jpeg, image/png"
                    @change="changeFile($event, 'logo')"
                  />
                  <small>Current Logo:</small><br />
                  <img
                    v-if="logo_url !== null"
                    :src="logo_url"
                    class="media_file"
                  />
                  <small class="text-danger">{{ validationsCategory }}</small>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <b-row>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <label for="benefit">Keuntungan:</label>
                  <div v-if="formPayload.information.benefit.length === 0">
                    <button
                      class="btn btn-primary"
                      @click="addItem($event, 'benefit')"
                    >
                      Add
                    </button>
                  </div>
                  <template v-else>
                    <div
                      class="input-group bank-input-group mb-1"
                      v-for="(benefit, index) in formPayload.information
                        .benefit"
                      :key="index"
                    >
                      <input
                        :id="'benefit' + index"
                        type="text"
                        class="form-control w-100"
                        v-model="formPayload.information.benefit[index]"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary"
                          @click="
                            index === 0
                              ? addItem($event, 'benefit')
                              : removeItem($event, index, 'benefit')
                          "
                        >
                          {{ index === 0 ? "+" : "-" }}
                        </button>
                      </div>
                    </div>
                    <small
                      v-for="(validation, index) in validations.benefit"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </template>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="form-group">
                  <label for="term_and_condition">Syarat dan Ketentuan:</label>
                  <div
                    v-if="
                      formPayload.information &&
                      formPayload.information.term_and_condition.length === 0
                    "
                  >
                    <button
                      class="btn btn-primary"
                      @click="addItem($event, 'terms')"
                    >
                      Add
                    </button>
                  </div>
                  <template v-else>
                    <div
                      v-for="(term, index) in formPayload.information
                        .term_and_condition"
                      :key="index"
                    >
                      <div class="input-group bank-input-group mb-1">
                        <input
                          :id="'term' + index"
                          type="text"
                          class="form-control w-100"
                          v-model="
                            formPayload.information.term_and_condition[index]
                          "
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-primary"
                            @click="
                              index === 0
                                ? addItem($event, 'terms')
                                : removeItem($event, index, 'terms')
                            "
                          >
                            {{ index === 0 ? "+" : "-" }}
                          </button>
                        </div>
                      </div>
                      <small
                        v-for="(
                          validation, index
                        ) in validations.term_and_condition"
                        :key="`errorName${index}`"
                        class="text-danger"
                        >{{ validation }}</small
                      >
                    </div>
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-col cols="12" class="p-0 mt-2">
            <button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary"
              :disabled="isLoading"
            >
              {{ isLoading ? "Loading..." : "Update" }}
            </button>
            &nbsp; &nbsp;
            <a
              @click="handleBackButton()"
              variant="secondary"
              class="btn waves-effect waves-float waves-light btn-secondary"
            >
              Cancel
            </a>
          </b-col>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import _ from "lodash";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    quillEditor,
    BFormFile,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      moment,
      isLoading: false,
      formPayload: {
        name: "",
        description: "",
        status: "",
        banner: null,
        logo: null,
        order: "",
        class: {},
        information: {
          benefit: [],
          term_and_condition: [],
        },
      },
      image_url: null,
      logo_url: null,
      selectedOption: "",
      validations: "",
      validationsCategory: "",
      eLearningList: [],
      isLoadingElearningList: false,
    };
  },

  async created() {
    await this.loadElearningList();
    this.loadBank();
  },

  methods: {
    searchCategories: _.debounce(async function (e) {
      const search = e.target.value;
      await this.loadElearningList(search);
    }, 300),
    async loadElearningList(search) {
      // this.isLoadingElearningList = true;
      const response = await this.$http.get("/api/v1/admin/e-learning", {
        params: {
          type: "financial",
          search: search != null ? search : null,
        },
      });
      this.eLearningList = response.data.data.items.map((item) => ({
        name: item.title,
        value: item.uuid,
      }));
      // this.isLoadingElearningList = false;
    },
    addItem(event, name) {
      event.preventDefault();
      if (
        this.formPayload.information &&
        this.formPayload.information.benefit &&
        name === "benefit"
      ) {
        this.formPayload.information.benefit.push("");
      } else {
        this.formPayload.information.term_and_condition.push("");
      }
    },
    removeItem(event, index, name) {
      event.preventDefault();
      if (
        this.formPayload.information &&
        this.formPayload.information.benefit &&
        name === "benefit"
      ) {
        this.formPayload.information.benefit.splice(index, 1);
      } else {
        this.formPayload.information.term_and_condition.splice(index, 1);
      }
    },
    handleBackButton() {
      history.back();
    },
    loadBank() {
      const params = this.$route.params.uuid;
      this.$http.get("/api/v1/admin/banks/" + params).then((response) => {
        this.formPayload = response.data.data;
        this.image_url = this.formPayload.banner;
        this.logo_url = this.formPayload.logo;
        // this.selectedOption = { name: this.formPayload.class.title, value: this.formPayload.class.uuid };
        this.selectedOption = this.eLearningList.find(
          (eLearning) => eLearning.value === this.formPayload.class.uuid
        );
      });
    },
    changeFile(event, name) {
      if (name === "banner") {
        this.formPayload.banner = event.target.files[0];
        this.showPhoto(event.target.files[0], name);
      } else {
        this.formPayload.logo = event.target.files[0];
        this.showPhoto(event.target.files[0], name);
      }
    },

    showPhoto(file, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name === "banner") {
          this.image_url = e.target.result;
        } else {
          this.logo_url = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },

    saveItem() {
      this.isLoading = true;
      this.validations = "";
      this.validationsCategory = "";
      const params = this.$route.params.uuid;

      const payload = new FormData();

      const {
        information,
        class: classProp,
        ...restOfFormPayload
      } = this.formPayload;

      for (const key in restOfFormPayload) {
        payload.append(key, restOfFormPayload[key]);
      }

      if (this.selectedOption) {
        payload.append("class", this.selectedOption.value);
      }

      payload.append("information[title]", this.formPayload.information.title);
      payload.append(
        "information[loan_link]",
        this.formPayload.information.loan_link
      );
      payload.append(
        "information[product_info]",
        this.formPayload.information.product_info
      );

      if (this.formPayload.information?.benefit.length > 0) {
        for (let i = 0; i < this.formPayload.information.benefit.length; i++) {
          payload.append(
            `information[benefit][${i}]`,
            this.formPayload.information.benefit[i]
          );
        }
      }

      if (this.formPayload.information?.term_and_condition.length > 0) {
        for (
          let i = 0;
          i < this.formPayload.information.term_and_condition.length;
          i++
        ) {
          payload.append(
            `information[term_and_condition][${i}]`,
            this.formPayload.information.term_and_condition[i]
          );
        }
      }

      this.$http
        .post(`/api/v1/admin/banks/${params}`, payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "Bank Berhasil diedit!");
          this.$router.push({ name: "bank" });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Content",
              this.validations.content.join("")
            );
            errorNotification(
              this,
              "Publish Date",
              this.validations.publish_date.join("")
            );
            errorNotification(this, "Status", this.validations.status.join(""));
            errorNotification(this, "Title", this.validations.title.join(""));
          } else if (error.response.data.meta.messages) {
            this.validationsCategory = error.response.data.meta.messages;
            errorNotification(this, "Input Kosong!", this.validationsCategory);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}

.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
  margin-top: 16px;
}

.bank-input-group {
  flex-flow: row;
}

.bank-input-group span {
  width: 100%;
}

.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
</style>
